<template>
    <tr>
        <td>
            <basic-input v-model="customerData.name" placeholder="Nhập Họ và tên" :required="true"
                :value.sync="customerData.name" label="" v-validate="'required'" name="name" data-vv-as="Họ và Tên" />
        </td>
        <td>
            <basic-input v-model="customerData.phoneNumber" placeholder="0959xxxxxxx" :required="true"
                :value.sync="customerData.phoneNumber" v-validate="'required'" name="name" data-vv-as="Số điện thoại" />
        </td>
        <td>
            <basic-input v-model="customerData.contactSource" placeholder="Nguồn"
                :value.sync="customerData.contactSource" name="contactSource" />
        </td>
        <td>
            <basic-input v-model="customerData.dateOfBirth" placeholder="1999" :value.sync="customerData.dateOfBirth"
                name="dateOfBirth" />
        </td>
        <td>
            <basic-select placeholder="Chọn phân loại" name="Disease" :options="diseasesOpts" :solid="false"
                :required="true" :allowEmpty="false" track-by="value" value-label="text"
                v-model="customerData.diseaseType" :value.sync="customerData.diseaseType" changeValueByObject
                data-vv-as="Disease" />
        </td>
        <td>
            <basic-select placeholder="Chọn khóa" name="Course" :options="courses" :solid="false" :required="true"
                :allowEmpty="false" track-by="value" value-label="text" v-model="customerData.course"
                :value.sync="customerData.course" changeValueByObject data-vv-as="Course" />
        </td>
        <td>
            <basic-select placeholder="Chọn coach" name="Coach" :options="coaches" :solid="false" :required="true"
                :allowEmpty="false" track-by="value" value-label="text" v-model="customerData.coach"
                :value.sync="customerData.coach" changeValueByObject data-vv-as="Coach" />
        </td>
        <td>
            <button class="btn btn-danger" @click.stop="deleteCustomer(customerIndex)">
                <span class="svg-icon m-0">
                    <inline-svg src="/media/svg/icons/Neolex/Basic/trash.svg" />
                </span>
            </button>
        </td>
    </tr>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { DISEASES } from '../CustomerReceiveEnum';

const { mapState, mapActions } = createNamespacedHelpers('customerReceives');

export default {
    props: ['customerData', 'index', 'customerIndex'],
    computed: {
        ...mapState(['data', 'courses', 'coaches']),
        diseasesOpts() {
            return [
                { text: 'Tiền đái tháo đường', value: DISEASES.TIEN_DAI_THAO_DUONG },
                { text: 'Đái tháo đường típ 1', value: DISEASES.DAI_THAO_DUONG_TIP_1 },
                { text: 'Đái tháo đường típ 2', value: DISEASES.DAI_THAO_DUONG_TIP_2 },
                { text: 'Đái tháo đường thai kì', value: DISEASES.DAI_THAO_DUONG_THAI_KI },
                { text: 'Tăng huyết áp', value: DISEASES.TANG_HUYET_AP },
                { text: 'Béo phì', value: DISEASES.BEO_PHI },
                { text: 'Khác', value: DISEASES.KHAC },
            ];
        },
    },
    methods: {
        ...mapActions(['updateCustomer', 'deleteCustomer']),
    },
    watch: {
        customerData: {
            handler(newVal) {
                this.updateCustomer({ index: this.index, customerData: newVal });
            },
            deep: true,
        }
    }
};
</script>
